import { template as template_f9dbf53575b94e80a8f9ba959548cbde } from "@ember/template-compiler";
const SidebarSectionMessage = template_f9dbf53575b94e80a8f9ba959548cbde(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
