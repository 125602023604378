import { template as template_a5f679e75a6f467a94d4035157b095c1 } from "@ember/template-compiler";
const EmptyState = template_a5f679e75a6f467a94d4035157b095c1(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
