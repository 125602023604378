import { template as template_65bece2466a34d54822038f6b81a0e22 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_65bece2466a34d54822038f6b81a0e22(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
